<template>
  <div>
    <div class="pageTitle">群組管理</div>
    <div class="borderColor mb-4">
      <!-- 為跟其他類似頁面一致所設 div wrap -->
      <div class="d-flex m-2 mb-3">
        <button
          type="button"
          class="confirmButton p-1 my-1 ml-auto"
          style="width: 120px"
          @click="allUpdate"
        >
          更新群組
        </button>
      </div>

      <div class="tableWrap">
        <table class="text-center">
          <tr class="sticky-top">
            <th v-for="(i, index) in thTitle" :key="index">
              {{ i }}
            </th>
            <th>設定</th>
          </tr>

          <tr v-for="(i, idx) in data" :key="idx">
            <td>{{ i.role }}</td>

            <td>
              <b-form-checkbox-group
                v-model="data[idx].sideMenu"
                :options="sideMenuOptions"
                plain
                stacked
              />
            </td>

            <td>
              <b-form-checkbox-group
                v-model="data[idx].componentMenu"
                :options="componentMenuOptions"
                plain
                stacked
              />
            </td>

            <td>
              <b-form-checkbox-group
                v-model="data[idx].othersMenuRealtime"
                :options="othersMenuRealtimeOptions"
                plain
                stacked
              />
              <div
                class="selection"
                :class="{ invalid: updateHasInvalidList[idx] }"
              >
                <div class="mt-3 mb-2">告警等級顯示:</div>
                <multiselect
                  ref="multiselect"
                  v-model="data[idx].othersMenuAlertLevel"
                  :options="othersMenuAlertLevelOptions"
                  placeholder="選擇告警等級"
                  :max-height="600"
                  selectLabel="select"
                  deselectLabel="remove"
                  :preserveSearch="true"
                  :closeOnSelect="false"
                  :clearOnSelect="false"
                  :searchable="false"
                  :multiple="true"
                  :limit="2"
                  :disabled="!data[idx].othersMenuRealtime.length"
                  @input="sortSelectedAlertLevel(idx)"
                />
                <label
                  class="typo__label form__label"
                  v-show="updateHasInvalidList[idx]"
                >
                  請至少選擇一項
                </label>
              </div>
            </td>

            <td>
              <button
                type="button"
                class="btn dangerButton text-white p-1 m-1"
                style="width: 120px"
                @click="checkDeleteGroup(i.role, i.id)"
              >
                刪除群組
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                placeholder="輸入等級名稱"
                v-model="createRoleName"
              />
            </td>

            <td>
              <b-form-checkbox-group
                v-model="createSideMenu"
                :options="sideMenuOptions"
                plain
                stacked
              />
            </td>

            <td>
              <b-form-checkbox-group
                v-model="createComponentMenu"
                :options="componentMenuOptions"
                plain
                stacked
              />
            </td>

            <td>
              <b-form-checkbox-group
                v-model="createOthersMenuRealtime"
                :options="othersMenuRealtimeOptions"
                plain
                stacked
              />
              <div class="selection" :class="{ invalid: createHasInvalid }">
                <div class="mt-3 mb-2">告警等級顯示:</div>
                <multiselect
                  ref="multiselect"
                  v-model="createOthersMenuAlertLevel"
                  :options="othersMenuAlertLevelOptions"
                  placeholder="選擇告警等級"
                  :max-height="600"
                  selectLabel="select"
                  deselectLabel="remove"
                  :preserveSearch="true"
                  :closeOnSelect="false"
                  :clearOnSelect="false"
                  :searchable="false"
                  :multiple="true"
                  :limit="2"
                  :disabled="!createOthersMenuRealtime.length"
                  @input="sortSelectedAlertLevel('createNew')"
                />
                <label
                  class="typo__label form__label"
                  v-show="createHasInvalid"
                >
                  請至少選擇一項
                </label>
              </div>
            </td>

            <td>
              <button
                type="button"
                class="btn generallyButton text-white"
                style="width: 120px"
                @click="createGroup"
              >
                新增群組
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { routes } from '../router/index'
// import { getLoginRequest } from '../http/msalMethods'
import { mapState, mapActions, mapMutations } from 'vuex'
import { createGroupApi, updateGroupApi, deleteGroupApi } from '../api'
import Multiselect from 'vue-multiselect'
export default {
  name: 'GroupManage',
  components: {
    Multiselect
  },
  data () {
    return {
      operator: '',
      managerRole: '',
      thTitle: ['等級', '可觀看 SIDE 網頁', '可觀看 TOP 網頁', '可使用項目'],
      data: [],
      sideMenuOptions: [], // [{value:'', text:''}, {}, ...]
      componentMenuOptions: [], // [{value:'', text:''}, {}, ...]
      othersMenuRealtimeOptions: [], // ['', '', ...]
      othersMenuAlertLevelOptions: [
        '影響發電',
        '發電效率不佳',
        '不影響發電',
        '系統未連線',
        '系統短暫斷線'
      ],
      createSideMenu: [],
      createComponentMenu: [],
      createOthersMenuRealtime: [],
      createOthersMenuAlertLevel: [],
      createRoleName: ''
    }
  },
  watch: {
    // 取消勾選案場告警項目，清空告警等級
    hasRealtimeAlertList () {
      this.hasRealtimeAlertList.forEach((obj, idx) => {
        if (!obj.hasAlertPermission) {
          this.data[idx].othersMenuAlertLevel = []
        }
      })
    },
    // 取消勾選新群組案場告警項目，清空新群組告警等級
    createOthersMenuRealtime (newVal) {
      if (!newVal.length) {
        this.createOthersMenuAlertLevel = []
      }
    }
  },
  computed: {
    ...mapState(['roleArray', 'userDetail']),
    hasRealtimeAlertList () {
      return this.data.map((obj) => ({
        role: obj.role,
        hasAlertPermission:
          obj.othersMenuRealtime.includes('即時資訊 - 案場狀態告警')
      }))
    },
    updateHasInvalidList () {
      return this.data.map(
        (obj) =>
          !!obj.othersMenuRealtime.length && !obj.othersMenuAlertLevel.length
      )
    },
    createHasInvalid () {
      return (
        !!this.createOthersMenuRealtime.length &&
        !this.createOthersMenuAlertLevel.length
      )
    }
  },
  methods: {
    ...mapActions(['getAllGroups']),
    ...mapMutations(['UPDATE_ROLE_ARRAY']),
    // 排序已選告警等級項目
    sortSelectedAlertLevel (groupIdx) {
      const sortedRule = (a, b) =>
        this.othersMenuAlertLevelOptions.indexOf(a) -
        this.othersMenuAlertLevelOptions.indexOf(b)

      if (groupIdx === 'createNew') {
        this.createOthersMenuAlertLevel =
          this.createOthersMenuAlertLevel.sort(sortedRule)
      } else {
        this.data[groupIdx].othersMenuAlertLevel =
          this.data[groupIdx].othersMenuAlertLevel.sort(sortedRule)
      }
    },
    loadData () {
      this.data =
        this.managerRole === 'Whole_Admin'
          ? this.roleArray.map((obj) => ({
            ...obj,
            othersMenuRealtime: obj.othersMenu.filter((item) =>
              item.includes('即時資訊')
            ),
            othersMenuAlertLevel: obj.othersMenu.filter(
              (item) => !item.includes('即時資訊')
            )
          }))
          : this.roleArray
            .filter((obj) => obj.role !== 'Whole_Admin')
            .map((obj) => ({
              ...obj,
              othersMenuRealtime: obj.othersMenu.filter((item) =>
                item.includes('即時資訊')
              ),
              othersMenuAlertLevel: obj.othersMenu.filter(
                (item) => !item.includes('即時資訊')
              )
            }))
    },

    async createGroup () {
      // check
      if (this.createRoleName === '') return this.$swal('請輸入等級名稱')
      if (this.createHasInvalid) return this.$swal('請檢查輸入告警等級項目')

      const createGroup = await createGroupApi(
        this.operator,
        this.createRoleName,
        this.createComponentMenu,
        this.createSideMenu,
        this.createOthersMenuRealtime.concat(this.createOthersMenuAlertLevel)
      )

      if (createGroup?.data?.success) {
        this.createRoleName = ''
        this.createComponentMenu = []
        this.createSideMenu = []
        this.createOthersMenuRealtime = []
        this.createOthersMenuAlertLevel = []
        this.$swal('新增成功')
        await this.getAllGroups()
        this.loadData()
      } else {
        this.$swal(createGroup.data.msg ?? '新增資料異常')
      }
    },
    async allUpdate () {
      // check
      if (this.updateHasInvalidList.includes(true)) {
        return this.$swal('請檢查輸入告警等級項目')
      }

      const promise = []

      // 排序用
      const componentMenuValueList = this.componentMenuOptions.map(
        (obj) => obj.value
      )
      this.data.forEach((obj) => {
        obj.componentMenu = obj.componentMenu.sort(
          (a, b) =>
            componentMenuValueList.indexOf(a) -
            componentMenuValueList.indexOf(b)
        )
        // filter 之前存的沒有刪除，注意是 .value / .text 過濾名稱
        // console.log(
        //   obj.sideMenu.filter((item) =>
        //     this.sideMenuOptions.map((obj) => obj.value).includes(item)
        //   )
        // )
        // console.log(
        //   obj.othersMenu.filter((item) =>
        //     this.othersMenuRealtimeOptions.map((obj) => obj.text).includes(item)
        //   )
        // )
        promise.push(
          this.updateGroup(
            obj.role,
            obj.componentMenu,
            obj.sideMenu,
            obj.othersMenuRealtime.concat(obj.othersMenuAlertLevel), // 組合成 othersMenu
            obj.id
          )
        )
      })

      const success = await Promise.all(promise)
      if (success.every((isTrue) => isTrue)) {
        this.$swal('更新成功')
        await this.getAllGroups()
        this.UPDATE_ROLE_ARRAY()
        this.loadData()
      } else {
        this.$swal('更新資料異常')
      }
    },
    async updateGroup (role, componentMenu, sideMenu, othersMenu, id) {
      const updateGroup = await updateGroupApi(
        this.operator,
        role,
        componentMenu,
        sideMenu,
        othersMenu,
        id
      )
      if (updateGroup?.data?.success) return true
    },
    async deleteGroup (role, id) {
      const deleteGroup = await deleteGroupApi(this.operator, role, id)
      if (deleteGroup?.data?.success) {
        this.$swal('刪除成功')
        await this.getAllGroups()
        this.loadData()
      }
    },
    async checkDeleteGroup (role, id) {
      this.$swal
        .fire({
          title: `確定要刪除此群組?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `確定`,
          denyButtonText: `否`
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteGroup(role, id)
          }
        })
    }
  },
  async mounted () {
    this.operator = this.userDetail.account
    this.managerRole = this.userDetail.role

    this.loadData()

    const siteRoutes = routes.find(
      (x) => x.component && x.component.name === 'langRouterView'
    )

    const releaseSite = siteRoutes.children.filter(
      (x) => x.meta && x.meta.release
    )

    this.sideMenuOptions = releaseSite.map((route) => ({
      value: route.name,
      text: route.meta.zhTw
    }))

    const releaseTopSite = siteRoutes.children
      .find((x) => x.path === 'siteinfo')
      .children.filter((x) => x.meta && x.meta.release)

    this.componentMenuOptions = releaseTopSite.map((route) => ({
      value: route.name,
      text: route.meta.zhTw
    }))

    const releaseOthers = ['即時資訊 - 案場狀態告警'] // 若名稱有改，記得判斷 includes(名稱) 的地方也要改

    this.othersMenuRealtimeOptions = releaseOthers.map((item) => ({
      value: item,
      text: item
    }))
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
:deep(.selection) {
  padding-left: 20px;

  // cover vue-multiselect style
  .multiselect {
    color: #35495e;
  }

  .multiselect__tags {
    border-radius: 4px;
  }

  .multiselect__tag {
    background: #7db9e8;
    margin-bottom: 2px;
    border-radius: 4px;
  }

  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: #2d738f;
  }

  .multiselect__option--highlight {
    background: #7db9e8;
    outline: none;
    color: white;
  }

  .multiselect__option--highlight:after {
    content: attr(data-select);
    background: #7db9e8;
    color: white;
  }

  .multiselect__option--selected {
    background: #527ea1;
    color: white;
    font-weight: 700;
  }

  .multiselect__option--selected:after {
    content: "selected";
    background: #527ea1;
    color: white;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background: #b3b6b9;
    color: #fff;
  }

  .multiselect__option--selected.multiselect__option--highlight:after {
    background: #b3b6b9;
    content: attr(data-deselect);
    color: #fff;
  }

  .multiselect__placeholder {
    color: #9fa2a4;
    margin-bottom: 0;
    margin-left: 3px;
    padding-top: 1px;
    letter-spacing: 0.5px;
    font-size: 15px;
  }

  .multiselect__input::placeholder {
    color: #9fa2a4;
  }

  .multiselect__single {
    color: #434445;
  }
}
:deep(.invalid) {
  .typo__label {
    color: #f04124;
    font-size: 14px;
    padding-left: 12px;
  }
  .multiselect__tags {
    border-color: #f04124;
  }
}
</style>

<style lang="scss" scoped>
table td {
  font-size: 17px;
  font-weight: 700;
  padding: 5px 5px;
  min-width: 220px;
  width: 18%;
}

// 可觀看 Side
table td:nth-child(2) {
  padding: 15px 20px;
  text-align: left;
  vertical-align: top;
  min-width: 200px;
}

// 可觀看 Top
table td:nth-child(3) {
  padding: 15px 20px;
  text-align: left;
  vertical-align: top;
  min-width: 200px;
}

// 可使用項目
table td:nth-child(4) {
  padding: 15px 20px;
  text-align: left;
  vertical-align: top;
  min-width: 300px;
  width: 20%;
}

// 設定
table td:nth-child(5) {
  width: 10%;
  min-width: 150px;
}

.tableWrap {
  height: calc(100vh - 210px);
  overflow-x: auto;
  min-height: 450px;

  table {
    th {
      background-color: rgb(246, 247, 245);
      font-size: 17px;
    }
    tr {
      border-bottom: 2px solid rgba(190, 195, 177, 0.37);
    }
  }
  @media screen and (max-width: 575.98px) {
    height: calc(100vh - 260px);
  }
}
</style>
